import '../styles/templates/privacy-policy.scss'

import React from 'react'
import ReactMarkdown from 'react-markdown'

import { PAGE_NAME } from '../../config/cms'
import { Layout } from '../components/layout'
import { PageHeading } from '../components/page-heading'
import { usePageData } from '../hooks/cms/use-page'
import { PageComponentFactory } from '../utils/pages'

const TEMPLATE_NAME = PAGE_NAME.PrivacyPolicy

const CaseStudiesPage = PageComponentFactory(() => {
  const { heading, policyContentSection } = usePageData(PAGE_NAME.PrivacyPolicy)

  return (
    <Layout variant="secondary" page={TEMPLATE_NAME} pathname="/privacy-policy">
      <main className="privacy-policy-page section">
        <section>
          <PageHeading>{heading}</PageHeading>
          <div className="privacy-policy-page__divider" />
          <div className="privacy-policy-page__policy-list">
            {policyContentSection.map(({ heading, caption }, index) => (
              <div key={index}>
                <h3>{heading}</h3>
                <ReactMarkdown>{caption}</ReactMarkdown>
              </div>
            ))}
          </div>
          <div className="privacy-policy-page__divider" />
        </section>
      </main>
    </Layout>
  )
})

export default CaseStudiesPage
